<template>
  
  <div>
    <div>
      <Slider></Slider>
    </div>
    <section style="background-color: var(--white);" class=" d-flex">
  
      <div class="text-dark w-100 text-center">
        <div class=" d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 0;">
            <router-link to="/gifts">
              <div class="row ">
                <div class=" justify-content-between">                
                  <span class="pl-5" style="color: #6c6c6c;">Promotions</span>
                </div>
              </div>
              <div>
                
             </div>
            </router-link>
          </div>

      </div>
    </section>

    <section style="position: relative; z-index: 500; margin-top: 10px;" class="feature-menu p0 pb-1">
        <div class="icons-wrapper top-menu-items d-flex justify-content-between">
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/my-account">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/bonus.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Bonus</span>
            </router-link>
          </div>
      
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/gifts">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/promos.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Promotions</span>
            </router-link>
          </div>
      
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/my-account">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/deposit.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Deposit</span>
            </router-link>
          </div>
      
          <div class="feature-icon-item-wrapper d-flex flex-column align-items-center justify-content-center text-center" style="flex-grow: 1;">
            <router-link to="/gift">
              <div class="icon-container">
                <img style="width: 40px; height: 40px;" src="/img/topNav/download.png" class="menu-icon-svg">
              </div>
              <span style="color: #6c6c6c;">Download</span>
            </router-link>
          </div>
        </div>
      </section>

    <div style="background-color: var(--gray-white);" class="promotions">
      <div class="list">
        <!-- <div class="no-games-message">
          Promotions Coming Soon.
        </div> -->
        <div class="" v-for="(promotion, index) in promotions" :key="index">
          <router-link :to="promotion.link">
            <img :src="`/img/carousel/` + promotion.img" alt="Promotion image"
              style="max-width: 100%; height: auto;border-radius: 10px;" />
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// const Slider = () => import('./SliderCasino.vue');

export default {
  name: 'Offers',
  data() {
    return {
      promotions: [
        {
          title: "Title",
          description: "Decription !" +
            "",
          img: "freespin.png",
          link: "/",
        },
        // {
        //   title: "Title",
        //   description: "Decription !" +
        //     "",
        //   img: "BANNER02.jpg",
        //   link: "/deposit",
        // },
        // {
        //   title: "Title",
        //   description: "Decription !" +
        //     "",
        //   img: "BANNER03.jpg",
        //   link: "/deposit",
        // },
        // {
        //   title: "Title",
        //   description: "Decription !",
        //   img: "promos_2.png"
        // },
        // {
        //   title: "Title",
        //   description: "Decription !",
        //   img: "promos_3.png"
        // },
        // {
        //   title: "Title",
        //   description: "Decription !!",
        //   img: "promos_4.png"
        // },
        // {
        //   title: "Title",
        //   description: "Decription !!",
        //   img: "slider_5.jpeg"
        // },
      ],
    };
  },
  components: {},
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "offers");
    this.reloadProfile();

  }

}
</script>

<style scoped>
.no-games-message {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: #283544;
  background-color: #ffffff;
  border: 1px solid #283544;
  border-radius: 5px;
  margin: 20px;
}

.promotions {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  flex-direction: column;
  color: #000000;
}

.card {
  width: 100%;
  flex-grow: 1;
  background-color: var(--lightest-gray1);
  ;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  color: #fff;
}

.card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card h3 {
  font-size: 18px;
  margin: .5em 0;
}

.card p {
  line-height: 1.6;
}
</style>